/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from "uuid";

export const createTabSessionStorage = () => {
  const tabId = sessionStorage.getItem("tabId") || uuidv4();
  sessionStorage.setItem("tabId", tabId);
  return tabId;
};

export const getTabSpecificKey = (baseKey) => {
  const tabId = sessionStorage.getItem("tabId");
  return `${baseKey}_${tabId}`;
};

export const setTabSpecificItem = (baseKey: string, value: any): void => {
  const tabId = sessionStorage.getItem("tabId");
  const key = `${baseKey}_${tabId}`;
  localStorage.setItem(key, JSON.stringify(value));
};

export const getTabSpecificItem = <T>(baseKey: string): T | null => {
  const tabId = sessionStorage.getItem("tabId");
  const key = `${baseKey}_${tabId}`;
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const deleteTabSpecificItem = (baseKey) => {
  const key = getTabSpecificKey(baseKey);
  localStorage.removeItem(key);
};
export const clearTabSpecificItems = () => {
  const tabId = sessionStorage.getItem("tabId");
  if (!tabId) return;

  Object.keys(localStorage).forEach((key) => {
    if (key.includes(tabId)) {
      localStorage.removeItem(key);
    }
  });
};
