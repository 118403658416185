import ApolloClient from "apollo-client";
import axios from "axios";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { useAuthToken } from "./auth";
import { getTabSpecificItem } from "utils/sessionManager";

// Auth middleware to add the Authorization header
const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
    return forward(operation);
  });

// Axios instance with base URL and global headers
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_GRAPH_ENDPOINT,
});

httpClient.interceptors.request.use((config) => {
  // Get tab-specific hash ID for the request
  const hashId = getTabSpecificItem("hashId");
  config.headers.Authorization = `Bearer ${process.env.REACT_APP_MAGIC_KEY}`;

  return config;
});

export const useAppApolloClient = () => {
  const [authToken, setAuthToken] = useAuthToken();

  // Function to fetch a new session token
  const getToken = async () => {
    try {
      const hashId = getTabSpecificItem("hashId");

      if (!hashId) {
        console.warn("No hashId found for this tab");
        return;
      }

      const response = await httpClient.post(
        process.env.REACT_APP_GRAPH_ENDPOINT,
        {
          query: `mutation generateSessionToken($personId: String) {
            generateSessionToken(personId: $personId) {
              person {
                sessionToken
                sessionTokenExpiry
              }
            }
          }`,
          variables: {
            personId: hashId,
          },
        }
      );

      const person = response.data.data.generateSessionToken.person;
      if (person?.sessionToken && person?.sessionTokenExpiry) {
        const exp = new Date(person.sessionTokenExpiry);
        setAuthToken(person.sessionToken, exp);
      }
    } catch (error) {
      console.error("Failed to fetch session token", error);
    }
  };

  // Error handling link to manage GraphQL and network errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      const hashId = getTabSpecificItem("hashId");
      if (
        networkError.result?.error === "Authentication Failed" ||
        (networkError.result?.error ===
          "Request cannot be completed, transcript access denied." &&
          hashId)
      ) {
        console.warn("Authentication error, fetching new token for tab...");
        getToken();
      }
    }
  });

  // Get the effective token to use (auth token or fallback to magic key)
  const effectiveToken = authToken || process.env.REACT_APP_MAGIC_KEY;

  // Apollo Client configuration with links
  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authMiddleware(effectiveToken),
      new HttpLink({
        uri: process.env.REACT_APP_GRAPH_ENDPOINT,
      }),
    ]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        const tabId = sessionStorage.getItem("tabId");
        if (object.id) {
          return `${object.__typename}:${object.id}:${tabId}`;
        }
        return null;
      },
    }),
  });

  return client;
};

// Helper to clear Apollo cache for specific tab
export const clearTabCache = async (client) => {
  await client.clearStore();
  const tabId = sessionStorage.getItem("tabId");
  console.log(`Cleared cache for tab ${tabId}`);
};
