import { useApolloClient } from "@apollo/react-hooks";
import { useCookies } from "react-cookie";

import { useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';

const TOKEN_NAME = "sessionToken_cass_web" as const;
type TokenName = typeof TOKEN_NAME;

// Type for storing multiple tokens
type TabTokens = {
  [key: string]: string; // Allow string indexing for dynamic tab IDs
};

export const useAuthToken = () => {
  // Use a more flexible type for cookies
  const [cookies, setCookie, removeCookie] = useCookies<TokenName | string>([
    TOKEN_NAME,
  ]);

  useEffect(() => {
    const tabId = sessionStorage.getItem("tabId") || uuidv4();
    sessionStorage.setItem("tabId", tabId);
  }, []);

  const getTabSpecificKey = (baseKey: string): string => {
    const tabId = sessionStorage.getItem("tabId");
    return `${baseKey}_${tabId}`;
  };

  const setAuthToken = (authToken: string, expiry: Date) => {
    if (navigator.cookieEnabled) {
      // Store the token in a tab-specific way while maintaining type safety
      const cookieOptions = {
        expires: expiry,
        sameSite: "none" as const,
        secure: true,
        path: "/",
      };

      // Store both the original token and the tab-specific one
      setCookie(TOKEN_NAME, authToken, cookieOptions);
      setCookie(getTabSpecificKey(TOKEN_NAME), authToken, cookieOptions);
    } else {
      // Fallback to localStorage if cookies are disabled
      const storageData = { authToken, expiry };
      localStorage.setItem(
        getTabSpecificKey(TOKEN_NAME),
        JSON.stringify(storageData)
      );
    }
  };

  const getAuthToken = (): string | null => {
    const tabSpecificKey = getTabSpecificKey(TOKEN_NAME);

    if (navigator.cookieEnabled) {
      // Try to get tab-specific token first, fall back to general token
      return cookies[tabSpecificKey] || cookies[TOKEN_NAME] || null;
    } else {
      const storedToken = localStorage.getItem(tabSpecificKey);
      if (storedToken) {
        const { authToken, expiry } = JSON.parse(storedToken);
        const tokenExpiry = new Date(expiry);
        if (new Date() < tokenExpiry) {
          return authToken;
        } else {
          localStorage.removeItem(tabSpecificKey);
        }
      }
    }
    return null;
  };

  const removeAuthToken = () => {
    const tabSpecificKey = getTabSpecificKey(TOKEN_NAME);
    if (navigator.cookieEnabled) {
      removeCookie(TOKEN_NAME);
      removeCookie(tabSpecificKey);
    } else {
      localStorage.removeItem(tabSpecificKey);
    }
  };

  return [getAuthToken(), setAuthToken, removeAuthToken] as const;
};
// Custom hook to handle user logout
export const useLogout = () => {
  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore(); // Clear Apollo client store
    removeAuthToken(); // Remove auth token
  };

  return logout;
};
